import axios from '@/axios.js'

export default {
  // addItem({ commit }, item) {
  //   return new Promise((resolve, reject) => {
  //     axios.post("/api/data-list/products/", {item: item})
  //       .then((response) => {
  //         commit('ADD_ITEM', Object.assign(item, {id: response.data.id}))
  //         resolve(response)
  //       })
  //       .catch((error) => { reject(error) })
  //   })
  // },
  fetchUsers ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get('/users')
        .then(({data: response}) => {
          console.log(response.data.users)
          commit('SET_USERS', response.data.users)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchUser (context, userId) {
    return new Promise((resolve, reject) => {
      axios.get(`/users/${userId}`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  storeData (context, payload) {
    return new Promise((resolve, reject) => {
      payload.hotelId = parseInt(payload.hotelId)
      axios.post('/users', payload)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  updateData (context, payload) {
    return new Promise((resolve, reject) => {
      axios.post(`user/${payload.id}`, payload.body)
           .then(res => {
             resolve(res)
           })
           .catch(err => {
             reject(err)
           })
    })
  },

  destroyData ({ commit }, userId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/user/${userId}`)
        .then((response) => {
          commit('REMOVE_RECORD', userId)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  }
}
