<template>
  <vx-card v-show="!loading">
    <vs-row>
      <h4 class="mb-5">Edit User</h4>
    </vs-row>

    <vs-row vs-w="12">
      <vs-col vs-w="6">
        <vs-row vs-w="12">
          <vs-col vs-w="12" class="mb-3">
            <span class="mb-2 block text-sm">Role (*)</span>
            <v-select
              label="name"
              :options="roleOptions"
              :reduce="(role) => role.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.role_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("role_id")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3" v-if="payload.role_id == 3">
            <span class="mb-2 block text-sm">Brand (*)</span>
            <v-select
              label="name"
              :options="brandOptions"
              :reduce="(brand) => brand.id"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="payload.brand_id"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("brand_id")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              label="Full Name (*)"
              v-model="payload.name"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("name")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              icon-no-border
              label="Username (*)"
              v-model="payload.username"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("username")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              type="email"
              class="w-full"
              icon-pack="feather"
              icon="icon-mail"
              icon-no-border
              label="E-mail Address (*)"
              v-model="payload.email"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("email")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-3">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-smartphone"
              icon-no-border
              label="Mobile Phone Number (*)"
              v-model="payload.no_phone"
            />
            <span class="block text-sm mt-2 text-danger">{{
              errors.first("no_phone")
            }}</span>
          </vs-col>

          <vs-col vs-w="12" class="mb-2">
            <div class="vx-col w-full" @click.prevent="storeData">
              <vs-button class="mr-3 mb-2">Submit</vs-button>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="6" class="pl-8">
        <div class="flex items-start flex-col sm:flex-row">
          <img :src="url" class="rounded mr-4" width="350" height="350" />
          <div>
            <input
              type="file"
              @change="addImage"
              class="hidden"
              ref="update_logo_input"
              accept="image/*"
            />
            <vs-button
              @click="$refs.update_logo_input.click()"
              class="mr-4 mb-2"
              type="filled"
              icon-pack="feather"
              icon="icon-edit"
            ></vs-button>
            <vs-button
              @click="removeImage"
              type="border"
              color="danger"
              icon-pack="feather"
              icon="icon-trash"
            ></vs-button>
          </div>
        </div>
        <span class="block text-sm mt-2 text-danger">{{
          errors.first("avatar")
        }}</span>
      </vs-col>
    </vs-row>
  </vx-card>
</template>

<script>
import axios from "@/axios";
import vSelect from "vue-select";

// Store Module
import moduleUserManagement from "@/store/user-management/moduleUserManagement.js";
import identificationTypeStore from "@/store/identification-type";

export default {
  name: "UserEdit",

  metaInfo: {
    title: "Edit User",
    titleTemplate: "%s - Vancy Net",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },

  components: {
    "v-select": vSelect,
  },

  data: () => ({
    payload: {
      role_id: "",
      brand_id: "",
      name: "",
      username: "",
      email: "",
      no_phone: "",
      avatar: "",
      identification_type_id: "",
      identification_number: "",
    },

    url: null,
    loading: true,

    // Vselect options
    roleOptions: [
      {
        id: 1,
        name: "Super Admin",
      },
      {
        id: 2,
        name: "Admin",
      },
      {
        id: 3,
        name: "User",
      },
    ],
    brandOptions: [],
  }),

  computed: {
    url() {
      return "https://via.placeholder.com/350";
    },

    decryptedID() {
      return this.$secure.decrypt(this.$route.params.id);
    },

    identifications() {
      return this.$store.state.identificationType.identificationTypes || [];
    },
  },

  methods: {
    getBrands() {
      axios
        .get("/hotels")
        .then(({ data: res }) => {
          this.brandOptions = res.data.map((brand) => {
            return {
              id: brand.id,
              name: brand.name,
            };
          });
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },

    addImage(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      this.payload.avatar = file;
    },

    removeImage() {
      this.url = "https://via.placeholder.com/350";
      this.payload.avatar = "";
    },

    getData() {
      this.$vs.loading({
        type: "sound",
      });

      this.$store
        .dispatch("userManagement/fetchUser", this.decryptedID)
        .then(({ data: res }) => {
          this.payload.role_id =
            res.data.roles.length > 0 ? res.data.roles[0].id : "";
          this.payload.brand_id = res.data.brand.id;
          this.payload.username = res.data.username;
          this.payload.email = res.data.email;
          this.payload.name = res.data.profile.name;
          this.payload.no_phone = res.data.no_phone;
          this.url =
            res.data.profile.avatar == "users/default.png"
              ? "https://via.placeholder.com/350"
              : res.data.profile.avatar;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally((res) => {
          this.loading = false;
          this.$vs.loading.close();
        });
    },

    storeData() {
      const formData = new FormData();
      // set formData
      formData.set("role_id", this.payload.role_id);
      formData.set("username", this.payload.username);
      formData.set("email", this.payload.email);
      formData.set("name", this.payload.name);
      formData.set("no_phone", this.payload.no_phone);
      formData.set(
        "identification_type_id",
        this.payload.identification_type_id.id
      );
      formData.set("identification_number", this.payload.identification_number);
      if (this.payload.avatar !== "") {
        formData.set("avatar", this.payload.avatar);
      }
      formData.set("_method", "PUT");

      this.$store
        .dispatch("userManagement/updateData", {
          id: this.decryptedID,
          body: formData,
        })
        .then(({ data: res }) => {
          this.$vs.notify({
            title: "Successful",
            text: res.msg_status,
            iconPack: "feather",
            icon: "icon-check-circle",
            color: "success",
          });

          this.$router.push("/users");
        })
        .catch((err) => {
          this.$catchErrorResponse(err);
        });
    },
  },

  created() {
    if (!moduleUserManagement.isRegistered) {
      this.$store.registerModule("userManagement", moduleUserManagement);
      moduleUserManagement.isRegistered = true;
    }
    if (!identificationTypeStore.isRegistered) {
      this.$store.registerModule("identificationType", identificationTypeStore);
      identificationTypeStore.isRegistered = true;
    }

    this.getData();
    this.getBrands();
    this.$store.dispatch("identificationType/fetchIdentificationTypes");
  },
};
</script>

<style></style>
