import axios from "@/axios.js";

export default {
  async fetchIdentificationTypes({ commit }) {
    try {
      const { data } = await axios.get(`/vancynet/identification-type`);
      commit("setIdentificationType", data.data);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchIdentificationType({ commit }, params) {
    try {
      const { data } = await axios.get(
        `/vancynet/identification-type/${params}`
      );
      return { success: true, message: data.data };
    } catch (error) {
      return { success: false, message: error.message };
    }
  },
  async storeIdentificationType({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/identification-type`,
        params
      );
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async updateIdentificationType({ commit }, params) {
    try {
      const { data } = await axios.post(
        `/vancynet/identification-type/${params.id}`,
        params.body
      );
      return { success: true, msg_status: data.msg_status };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
  async removeIdentificationType({ dispatch }, params) {
    try {
      const { data } = await axios.delete(
        `/vancynet/identification-type/${params}`
      );
      dispatch("fetchIdentificationTypes");
      return { success: true };
    } catch (error) {
      console.log(error);
      return {
        success: false,
        message: error.response ? error.response.data.message : error.message,
      };
    }
  },
};
